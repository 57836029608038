<template>
    <v-row dense>
      <v-col cols="12">
        <v-card
          :color="!selectedImage ? 'grey lighten-2' : null"
        >
          <v-responsive :aspect-ratio="1">
            <v-card-text color="grey lighten-2" class="d-flex justify-center align-center fill-height" v-if="!selectedImage">
              <v-icon color="white" x-large>fas fa-image</v-icon>
            </v-card-text>
            <v-card-text
              color="grey lighten-2"
              class="d-flex justify-center align-center fill-height"
              v-else
              @click.stop="viewActive"
            >
              <v-hover v-slot="{ hover }">
                <v-img
                  :src="selectedImage.url"
                  :aspect-ratio="1"
                  height="100%"
                  width="100%"
                  max-height="100%"
                  max-width="100%"
                  contain
                >
                  <v-overlay v-if="hover" :absolute="true" :opacity="0.25">
                    <v-icon>fas fa-search</v-icon>
                  </v-overlay>
                </v-img>
              </v-hover>
            </v-card-text>
          </v-responsive>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="!noLibrary">
        <image-library
          :key="'mine'"
          ref="library"
          :images="images"
          :single-row="true"
          :img-height="50"
          :img-width="50"
          no-upload
          @preview-click="onPreviewClick"
          no-preview
          :viewOnly="!editing"
          @delete-image="$emit('delete-image', $event)"
        ></image-library>
      </v-col>

    </v-row>
</template>

<script>
import ImageLibrary from '@/components/Utils/ImageLibrary.vue'

export default {
  props: ['images', 'editing', 'noLibrary'],
  data () {
    return {
      selectedI: 0
    }
  },
  computed: {
    selectedImage () {
      return this.images && this.images.length > 0 ? this.images[this.selectedI] : false
    }
  },
  methods: {
    onPreviewClick (img) {
      const i = this.images.findIndex(f => f.id === img.id)
      this.selectedI = i
    },
    viewActive () {
      this.$refs.library.manualPreview(this.selectedImage, this.selectedI)
    }
  },
  components: {
    ImageLibrary
  },
  watch: {
    selectedImage: function (v) {
      if (!v) {
        if (this.selectedI && this.selectedI > this.images.length) {
          this.selectedI = 0
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
